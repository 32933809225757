
import { defineComponent } from "vue";
import { ApiSelectValue } from "table/dist/services/Api/types/ApiSelectValue";
import ApiHistoryData from "@/services/ApiHistoryData/ApiHistoryData";
import {
  ApiHelper,
  ApiService,
  ButtonComponent,
  NotificationsService,
  TInputComponent,
  TSelectComponent,
} from "table";
import AppRouterLink from "@/components/AppRouterLink.vue";

export default defineComponent({
  name: "ImportHistoryData",
  components: {
    AppRouterLink,
    TSelectComponent,
    TInputComponent,
    ButtonComponent,
  },
  props: {},
  emits: [],
  setup() {
    return {
      currentImportLink: ApiService.getUrl("/tables-and-actions?", {
        url: "/api/historical_data?action=get_import_info",
      }),
    };
  },
  data() {
    return {
      decreesType: undefined as ApiSelectValue | undefined,
      file: undefined as undefined | File,
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  methods: {
    onSelectFile(event: InputEvent) {
      const file = (event.target as HTMLInputElement).files![0];
      if (!file) {
        return;
      }

      this.file = file;
    },

    onImport() {
      if (!this.file || !this.decreesType) {
        return;
      }

      const title = "Импорт исторических данных";
      ApiHelper.wrapNotifyError(
        async () => {
          const result = await ApiHistoryData.import(
            this.file!,
            this.decreesType!.value,
          );
          NotificationsService.send({
            duration: 10000,
            text: result.json.message,
            title,
            type: "success",
          });
        },
        { title },
      );
    },
  },
});
