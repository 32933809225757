import { ApiService, HttpService } from "table";

class ApiHistoryData {
  getQueryParams = ApiService.getQueryParams;

  import(file: File, decree: string) {
    const formData = new FormData();
    formData.append("file", file);
    const url = ApiService.getUrl(`/api/historical_data?`, {
      action: "import",
      decree,
    });
    return HttpService.post<{ message: string }>(url, {
      body: formData,
    });
  }
}

export default new ApiHistoryData();
